import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const style = {
    height: '100vh',
    color: '#ec297b!important'
}

function Loader() {
  return (
    <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    alignContent="center"
    style={style}
  >
        <CircularProgress disableShrink className="booking-loading" />
    </Grid>
     );
}

export default Loader;
import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {nationality} from '../../context/arrays/nationality';
import {months} from '../../context/arrays/months';
import {days} from '../../context/arrays/days';
import {connect} from "react-redux";

class FormFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passengers: props.data
        }

    }

    handler = (e, field) => {
        this.props.save_booking.passengers[this.props.id][field] = e.target.value;
        this.forceUpdate();
    }

    consoleLog = () => {
        console.log(this.props.save_booking);
    }

    render(){
        const index = this.props.id;
        return (
            <div>
                <Grid container spacing={16}>
                    <Grid item xs={5}>
                        <TextField
                            label="Ime"
                            placeholder="npr. Pera"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={this.props.save_booking.passengers[index].name || ''}
                            onChange={(e) => this.handler(e, 'name')}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label="Prezime"
                            placeholder="npr. Perić"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={this.props.save_booking.passengers[index].surname || ''}
                            onChange={(e) => this.handler(e, 'surname')}
                            />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            select
                            label="Pol"
                            fullWidth
                            value={this.props.save_booking.passengers[index].title || ""}
                            onChange={(e) => this.handler(e, 'title')}
                            SelectProps={{
                                native: true,
                            }}
                            margin="dense"
                            variant="outlined"
                            >
                                <option key={0} value="" hidden></option>
                                <option key={1} value="mr">Muški</option>
                                <option key={2} value="mrs">Ženski</option>
                            </TextField>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            select
                            label="Državljanstvo"
                            fullWidth
                            value={this.props.save_booking.passengers[index].nationality || ""}
                            onChange={(e) => this.handler(e, 'nationality')}
                            SelectProps={{
                                native: true,

                            }}
                            margin="dense"
                            variant="outlined"

                            >
                            {nationality.map((option, i) => (
                                i === 0 ?
                                <option key={i} value="" hidden></option>
                                : i === 2 ?
                                <option key={i} value={option.value} disabled>
                                {option.label}
                                </option>
                                :
                                <option key={i} value={option.value}>
                                {option.label}
                                </option>
                            ))}
                            </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            select
                            label="Dan"
                            helperText="Dan rođenja"
                            value={this.props.save_booking.passengers[index].b_day || ""}
                            onChange={(e) => this.handler(e, 'b_day')}
                            SelectProps={{
                                native: true,
                            }}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            >
                            {days.map((day, i) => (
                                i === 0 ?
                                <option key={0} value="" hidden></option>
                                :
                                <option key={day} value={day}>
                                {day}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            select
                            label="Mesec"
                            helperText="Mesec rođenja"
                            value={this.props.save_booking.passengers[index].b_month || ""}
                            onChange={(e) => this.handler(e, 'b_month')}
                            SelectProps={{
                                native: true,
                            }}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            >
                            {months.map((month, i) => (
                                i === 0 ?
                                <option key={i} value="" hidden></option>
                                :
                                <option key={i} value={month.value}>
                                {month.label}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            helperText="Godina rođenja"
                            label="Godina"
                            placeholder="npr. 1997"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={this.props.save_booking.passengers[index].b_year || ''}
                            onChange={(e) => this.handler(e, 'b_year')} />
                    </Grid>

                    <Grid item xs={5}>
                        <TextField
                            label="Broj pasoša"
                            placeholder="npr. 009213289"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={this.props.save_booking.passengers[index].cardno || ''}
                            onChange={(e) => this.handler(e, 'cardno')}
                            />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            select
                            label="Dan"
                            helperText="Dan isteka pasoša"
                            value={this.props.save_booking.passengers[index].e_day || ""}
                            onChange={(e) => this.handler(e, 'e_day')}
                            SelectProps={{
                                native: true,
                            }}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            >
                            {days.map((day, i) => (
                                i === 0 ?
                                <option key={0} value="" hidden></option>
                                :
                                <option key={day} value={day}>
                                {day}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            select
                            label="Mesec"
                            helperText="Mesec isteka pasoša"
                            value={this.props.save_booking.passengers[index].e_month || ""}
                            onChange={(e) => this.handler(e, 'e_month')}
                            SelectProps={{
                                native: true,
                            }}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            >
                            {months.map((month, i) => (
                                i === 0 ?
                                <option key={i} value="" hidden></option>
                                :
                                <option key={i} value={month.value}>
                                {month.label}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            helperText="Godina isteka pasoša"
                            label="Godina"
                            placeholder="npr. 2020"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={this.props.save_booking.passengers[index].e_year || ''}
                            onChange={(e) => this.handler(e, 'e_year')} />
                    </Grid>
                </Grid>
                <p onClick={this.consoleLog}>Console Log</p>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        dialog: state.dialog,
        loading: state.loading,
        save_booking: state.save_booking,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBookingDetails: (payload) => dispatch({type: 'FLIGHT_DETAILS', payload: payload}),
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormFields);

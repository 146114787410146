import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import CheckIcon from "@material-ui/icons/CheckCircleRounded";
import AddIcon from "@material-ui/icons/AddCircleRounded";
import RemoveIcon from "@material-ui/icons/Cancel";
import {connect} from "react-redux";

class BaggageBox extends Component {

  state = {
    id: this.props.id,
    bag_id: this.props.bag_id,
    data: this.props.data,
    bagSelected: false,
    hover: false,
  }

  componentDidMount() {
    let category = this.state.data.category;
    if (this.props.fullState.reservation_bill.paidBags[this.state.id]
      && this.props.fullState.reservation_bill.paidBags[this.state.id].items
      && this.state.data.price.amount !== 0) {
      this.setState({bagSelected: true});
    }

    if (!this.props.fullState.reservation_bill.paidBags[this.state.id]
      && this.state.data.price.amount !== 0) {
      this.props.addHoldBags(this.state.id);
    }

    if (this.props.fullState.reservation_bill.freeBags[this.state.id]
      && !this.props.fullState.reservation_bill.freeBags[this.state.id].unmounted
      && this.state.data.price.amount === 0) {
      this.props.addFreeBags(this.state.id, category);
    }
  }

  componentWillUnmount() {
    this.props.bagWillUnmount(this.state.id)
  }

  isPriorityBag = () => {
    if (this.state.data.conditions.is_priority)
      return 'Samo uz kupljen prioritet';
  }

  isInfantBag = () => {
    const data = this.state.data.conditions.passenger_groups;
    if (data.length === 1 && data[0] === "infant")
      return 'Samo ukoliko dete (< 3god) putuje sa vama';
  }

  getBagName = () => {
    if (this.state.data.category === "personal_item")
      return "Licne stvari";
    if (this.state.data.category === "cabin_bag")
      return "Ručni prtljag";

    return "Dodatan prtljag";
  }

  addThisBag = () => {
    if (this.state.data.price.amount === 0) {
      return;
    }

    if (this.state.bagSelected) {
      this.removeThisBag(this.state.id, this.state.bag_id);
      return;
    }
    this.props.addPaidBags(this.state.id, this.state.bag_id, this.state.data.price.amount);
    this.setState({bagSelected: true});
  }

  removeThisBag = () => {
    this.setState({bagSelected: false})
    this.props.removePaidBag(this.state.id, this.state.bag_id, this.state.data.price.amount);
  }

  bagRestrictions = () => {
    const restrictions = this.state.data.restrictions;

    return restrictions.width + " x " + restrictions.height + " x " + restrictions.length + ", " + restrictions.weight + "kg";
  }

  addRemoveBagIcon = () => {
    let CheckIconColor = "#00C853";

    if (this.isInfantBag() || this.isPriorityBag())
      CheckIconColor = "#f9aa33";

    if (this.props.data.price.amount && !this.state.bagSelected)
      return <AddIcon style={{fontSize: 40, cursor: 'pointer'}}/>
    else
      return <CheckIcon style={{color: CheckIconColor, fontSize: 40}}/>
  }

  render() {

    const boxStyle = {
      border: '1px solid rgba(0,0,0,.23)',
      borderRadius: 4,
      textAlign: "center",
      minHeight: 180,
      position: "relative",
      transition: "all .3s ease-in-out"
    }

    let boxHoverClass = "";

    if (this.state.data.price.amount) {
      Object.assign(boxStyle, {cursor: "pointer"});
      boxHoverClass = "boxHover";
    }

    boxHoverClass += " " + this.state.data.category + "_icon";

    return (
      <Grid item md={3} onClick={this.addThisBag}>
        <Grid container alignContent={"center"} alignItems={"center"} style={boxStyle} className={boxHoverClass}>
          <div className="addedIcon">
            {this.addRemoveBagIcon()}
          </div>
          <div className="removeIcon">
            {this.state.bagSelected ?
              <RemoveIcon onClick={this.removeThisBag}/>
              : null}
          </div>

          <Grid item md={12} style={{lineHeight: 1.8}}>
            <span className="bagName">{this.getBagName()}</span>
            <br/>
            <span className="bagRestrictions">{this.bagRestrictions()}</span>
          </Grid>

          <Grid item md={12} className="priceTag">
            {this.state.data.price.amount ? this.state.data.price.amount + " EUR" : "BESPLATAN"}
          </Grid>

          <div className="bagCondition">
            {this.isInfantBag()} {this.isPriorityBag()}
          </div>
        </Grid>
      </Grid>
    )
  }
}


const mapStateToProps = state => {
  return {
    dialog: state.dialog,
    snackbar: state.snackbar,
    loading: state.loading,
    fullState: state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addFreeBags: (id = 0, category) => dispatch({type: 'ADD_FREE_BAGS', id, category}),
    addHoldBags: (id = 0) => dispatch({type: 'ADD_HOLD_BAGS', id}),
    addPaidBags: (id = 0, bag_id = 0, price) => {
      dispatch({type: 'ADD_PAID_BAGS', id, bag_id, price})
      dispatch({type: 'SHOW_SNACKBAR', message: 'Dodat je prtljag po ceni od ' + price + 'EUR', variant: 'success'})
    },
    removePaidBag: (id = 0, bag_id = 0, price) => dispatch({type: 'REMOVE_PAID_BAGS', id, bag_id, price}),
    bagWillUnmount: (id = 0) => dispatch({type: 'BAG_WILL_UNMOUNT', id})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaggageBox);

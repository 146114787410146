import React from 'react';

export default React.createContext({
    snackbar: {
        open: false,
        variant: 'error',
        message: 'Error Message',
    },
    save_booking: {
      lang: "en",
      bags: 3,
      passengers: [
        {
          name: "",
          surname: "",
          title: "",
          phone: "",
          birthday: null,
          b_day: null,
          b_month: null,
          b_year: "",
          expiration: null,
          e_day: null,
          e_month: null,
          e_year: "",
          cardno: "",
          nationality: "",
          nationality_full: "",
          email: "",
          category: "",
          hold_bags: {},
        },
      ],
      locale: "en",
      currency: "usd",
      booking_token: "",
      affily: 'najletovi',
      booked_at: 'najletovi',
      user_id: "test",
      secret_token: "test",
      immediate_confirmation: false
    },
    check_flights_response: [],
    check_flights_response: [],
    loading: false
});
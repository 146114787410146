import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export class DialogPopup extends Component {
    handleReload = () => {
        window.location.reload();
    }

    handleBackToSearch = () => {
        window.location = "https://www.najletovi.rs/";
    }

    render(){
        return(
            <Dialog
                open={this.props.show}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {this.props.body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.props.backToSearch ?
                        <Button onClick={this.handleBackToSearch}>
                        Nazad na pretragu
                        </Button>
                    : ''}
                    {this.props.reloadBtn ?
                        <Button onClick={this.handleReload} color="primary" autoFocus>
                            Osveži stranicu
                        </Button>
                    : ''}
                </DialogActions>
            </Dialog>
        )
    }
}

export default DialogPopup;

import React, {Component} from 'react';
import { Grid } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined'


export default class ItineraryFlightDetails extends Component {
    render() {
        const data = this.props.data;
        const textStyle = {
            fontSize: 11
        }
        return (
            <React.Fragment>
                <Grid container spacing={8} alignItems='center' alignContent='center'>
                    <Grid item style={{height: 25}}>
                        <img src={"//images.kiwi.com/airlines/64x64/" + data.airline.iata + ".png"} alt="" width="15" />
                    </Grid>
                    <Grid item style={textStyle}>
                        Avio kompanija: {data.airline.Name}
                    </Grid>
                </Grid>
                <Grid container spacing={8} alignItems='center' alignContent='center'>
                    <Grid item style={{height: 25}}>
                        <img src={"//images.kiwi.com/airlines/64x64/" + data.operating_airline.iata + ".png"} alt="" width="15" />
                    </Grid>
                    <Grid item style={textStyle}>
                        Avio kompanija koja obavlja let: {data.operating_airline.name}
                    </Grid>

                </Grid>
                <Grid container spacing={8} alignItems='center' alignContent='center'>
                    <Grid item style={{height: 25}}>
                        <InfoIcon style={{fontSize: 15}} />
                    </Grid>
                    <Grid item style={textStyle}>
                        Broj leta: {data.operating_airline.iata} {data.flight_no}
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}


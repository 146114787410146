import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PassengerContext from '../context/passenger-context';

class Payment extends Component {
  static contextType = PassengerContext;

  state = {
  }

  componentDidMount = () => {
    this.context.getUrl(
      this.props.match.params.from, 
      this.props.match.params.to, 
      this.props.match.params.curr,
      this.props.match.params.pass, 
      this.props.match.params.oneway,
      this.props.match.params.id,
      this.props.match.params.token
      );

    this.context.sendSaveBooking();
  }

    render() {
      const url_params = this.props.location.pathname.substr(9);
      return (
        <div className="container">
          <main>
            <h1>Payment</h1>
            <Link to={"/info/"+url_params} >Podaci putnika</Link>
          </main>
        </div>
      )
    }
  }

export default Payment;
import React, {Component} from 'react';
import FormFields from './FormFields';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Person from '@material-ui/icons/Person';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import Baggage from '@material-ui/icons/Work';
import BaggageOverview from './Baggage/BaggageOverview';
import '../../assets/css/default.css';

const FormGroup = (props) => {
  const index = props.id;
  return (
    <Card className="passenger-card">
      <div className="title-head">
        <span style={{height: 36, marginRight: 5}}><Person style={{fontSize: 40}}/></span>
        <span>{index ? index + '. Putnik' : 'Podaci putnika'}</span>
        {index ? <Button color="primary" className="remove-passenger"
                         onClick={() => props.removePassenger(index)}>Ukloni putnika</Button> : ''}
      </div>
      <hr/>
      <div className="info-box">
        <p>Navedite sva lična imena i prezimena tačno <strong>kao u pasošu/ličnoj karti</strong> da biste izbegli
          probleme pri ukrcavanju.</p>
      </div>

      <FormFields id={index} data={props.data}/>

      <div className="title-head">
        <span style={{height: 32, marginRight: 5}}><Baggage style={{fontSize: 35}}/></span>
        <span>Prtljag</span>
      </div>
      <hr/>
      <BaggageOverview id={index}/>

      <div className="bag-suggestion">
        <NotificationImportant style={{color: "#F9AA33", fontSize: 35, marginRight: 5}}/>
        <p><strong>Savet:</strong> Avio-kompanije obično naplaćuju više tarife za prtljag nakon što ste rezervisali
          karte. Uštedite tako što ćete sada dodati prtljag.</p>
      </div>
    </Card>
  )
}

export default FormGroup;

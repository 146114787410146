export const nationality  = [
{},
{ value: "rs", label: "Srbija" },
{ value: "none", label: "───────────" },
{ value: "ax", label: "Alandska ostrva" },
{ value: "al", label: "Albanija" },
{ value: "dz", label: "Alžir" },
{ value: "as", label: "Američka Samoa" },
{ value: "ad", label: "Andora" },
{ value: "ao", label: "Angola" },
{ value: "ai", label: "Angvila" },
{ value: "aq", label: "Antarktika" },
{ value: "ag", label: "Antigva i Barbuda" },
{ value: "ar", label: "Argentina" },
{ value: "am", label: "Armenija" },
{ value: "aw", label: "Aruba" },
{ value: "au", label: "Australija" },
{ value: "at", label: "Austrija" },
{ value: "af", label: "Avganistan" },
{ value: "az", label: "Azerbejdžan" },
{ value: "bs", label: "Bahami" },
{ value: "bh", label: "Bahrein" },
{ value: "bd", label: "Bangladeš" },
{ value: "bb", label: "Barbados" },
{ value: "be", label: "Belgija" },
{ value: "bz", label: "Belise" },
{ value: "by", label: "Belorusija" },
{ value: "bj", label: "Benin" },
{ value: "bm", label: "Bermuda" },
{ value: "bw", label: "Bocvana" },
{ value: "bo", label: "Bolivija" },
{ value: "ba", label: "Bosna i Hercegovina" },
{ value: "cx", label: "Božićna Ostrva" },
{ value: "br", label: "Brazil" },
{ value: "vg", label: "Britanska Devičanska Ostrva" },
{ value: "io", label: "Britansko Indijska Okeanska Teritorija" },
{ value: "bn", label: "Brunej" },
{ value: "bg", label: "Bugarska" },
{ value: "bf", label: "Burkina Faso" },
{ value: "bi", label: "Burundi" },
{ value: "bt", label: "Butan" },
{ value: "bv", label: "Buve Ostrva" },
{ value: "td", label: "Čad" },
{ value: "cf", label: "Centralno Afrička Republika" },
{ value: "cz", label: "Češka" },
{ value: "cl", label: "Čile" },
{ value: "me", label: "Crna Gora" },
{ value: "dk", label: "Danska" },
{ value: "cd", label: "Demokratska Republika Kongo" },
{ value: "dm", label: "Dominika" },
{ value: "do", label: "Dominikanska Republika" },
{ value: "je", label: "Džersi" },
{ value: "dj", label: "Džibuti" },
{ value: "eg", label: "Egipat" },
{ value: "ec", label: "Ekvador" },
{ value: "gq", label: "Ekvatorijalna Gvineja" },
{ value: "er", label: "Eritreja" },
{ value: "ee", label: "Estonija" },
{ value: "et", label: "Etiopija" },
{ value: "fo", label: "Farska Ostrva" },
{ value: "fj", label: "Fidži" },
{ value: "ph", label: "Filipini" },
{ value: "fi", label: "Finska" },
{ value: "fk", label: "Folklandska Ostrva" },
{ value: "fr", label: "Francuska" },
{ value: "gf", label: "Francuska Gvajana" },
{ value: "pf", label: "Francuska Polinezija" },
{ value: "tf", label: "Francuske Južne Teritorije" },
{ value: "ga", label: "Gabon" },
{ value: "gm", label: "Gambija" },
{ value: "gh", label: "Gana" },
{ value: "gi", label: "Gibraltar" },
{ value: "gr", label: "Grčka" },
{ value: "gd", label: "Grenada" },
{ value: "gl", label: "Grenland" },
{ value: "ge", label: "Gruzija" },
{ value: "gu", label: "Guam" },
{ value: "gg", label: "Gurnsi" },
{ value: "gp", label: "Gvadelupe" },
{ value: "gy", label: "Gvajana" },
{ value: "gt", label: "Gvatemala" },
{ value: "gn", label: "Gvineja" },
{ value: "gw", label: "Gvineja-Bisao" },
{ value: "ht", label: "Haiti" },
{ value: "hm", label: "Herd i Mekdonald Ostrva" },
{ value: "nl", label: "Holandija" },
{ value: "hn", label: "Honduras" },
{ value: "hk", label: "Hong Kong (S. A. R. Kina)" },
{ value: "hr", label: "Hrvatska" },
{ value: "in", label: "Indija" },
{ value: "id", label: "Indonezija" },
{ value: "iq", label: "Irak" },
{ value: "ir", label: "Iran" },
{ value: "ie", label: "Irska" },
{ value: "is", label: "Island" },
{ value: "tl", label: "Istočni Timor" },
{ value: "it", label: "Italija" },
{ value: "il", label: "Izrael" },
{ value: "jm", label: "Jamajka" },
{ value: "jp", label: "Japan" },
{ value: "ye", label: "Jemen" },
{ value: "jo", label: "Jordan" },
{ value: "gs", label: "Južna Džordžija i Južna Sendvič Ostrva" },
{ value: "kr", label: "Južna Koreja" },
{ value: "za", label: "Južnoafrička Republika" },
{ value: "ky", label: "Kajmanska Ostrva" },
{ value: "kh", label: "Kambodža" },
{ value: "cm", label: "Kamerun" },
{ value: "ca", label: "Kanada" },
{ value: "cv", label: "Kape Verde" },
{ value: "qa", label: "Katar" },
{ value: "kz", label: "Kazahstan" },
{ value: "ke", label: "Kenija" },
{ value: "cn", label: "Kina" },
{ value: "cy", label: "Kipar" },
{ value: "kg", label: "Kirgizstan" },
{ value: "ki", label: "Kiribati" },
{ value: "cc", label: "Kokos (Keling) Ostrva" },
{ value: "co", label: "Kolumbija" },
{ value: "km", label: "Komorska Ostrva" },
{ value: "cg", label: "Kongo" },
{ value: "cr", label: "Kostarika" },
{ value: "cu", label: "Kuba" },
{ value: "ck", label: "Kukova Ostrva" },
{ value: "kw", label: "Kuvajt" },
{ value: "la", label: "Laos" },
{ value: "ls", label: "Lesoto" },
{ value: "lv", label: "Letonija" },
{ value: "lb", label: "Liban" },
{ value: "lr", label: "Liberija" },
{ value: "ly", label: "Libija" },
{ value: "li", label: "Lihtenštajn" },
{ value: "lt", label: "Litvanija" },
{ value: "lu", label: "Luksemburg" },
{ value: "mg", label: "Madagaskar" },
{ value: "hu", label: "Mađarska" },
{ value: "yt", label: "Majote" },
{ value: "mo", label: "Makao (S. A. R. Kina)" },
{ value: "mk", label: "Makedonija" },
{ value: "mw", label: "Malavi" },
{ value: "mv", label: "Maldivi" },
{ value: "my", label: "Malezija" },
{ value: "ml", label: "Mali" },
{ value: "mt", label: "Malta" },
{ value: "um", label: "Manja Udaljena Ostrva SAD" },
{ value: "ma", label: "Maroko" },
{ value: "mh", label: "Maršalska Ostrva" },
{ value: "mq", label: "Martinik" },
{ value: "mu", label: "Mauricius" },
{ value: "mr", label: "Mauritanija" },
{ value: "mx", label: "Meksiko" },
{ value: "mm", label: "Mijanmar" },
{ value: "fm", label: "Mikronezija" },
{ value: "md", label: "Moldavija" },
{ value: "mc", label: "Monako" },
{ value: "mn", label: "Mongolija" },
{ value: "ms", label: "Monserat" },
{ value: "mz", label: "Mozambik" },
{ value: "na", label: "Namibija" },
{ value: "nr", label: "Nauru" },
{ value: "de", label: "Nemačka" },
{ value: "np", label: "Nepal" },
{ value: "ne", label: "Niger" },
{ value: "ng", label: "Nigerija" },
{ value: "ni", label: "Nikaragva" },
{ value: "nu", label: "Niue" },
{ value: "nf", label: "Norfolk Ostrvo" },
{ value: "no", label: "Norveška" },
{ value: "nc", label: "Nova Kaledonija" },
{ value: "nz", label: "Novi Zeland" },
{ value: "ci", label: "Obala Slonovače" },
{ value: "om", label: "Oman" },
{ value: "im", label: "Ostrvo Man" },
{ value: "pk", label: "Pakistan" },
{ value: "pw", label: "Palau" },
{ value: "ps", label: "Palestinska Teritorija" },
{ value: "pa", label: "Panama" },
{ value: "pg", label: "Papua Nova Gvineja" },
{ value: "py", label: "Paragvaj" },
{ value: "pe", label: "Peru" },
{ value: "pn", label: "Pitcairn" },
{ value: "pl", label: "Poljska" },
{ value: "pr", label: "Porto Riko" },
{ value: "pt", label: "Portugal" },
{ value: "re", label: "Rejunion" },
{ value: "rw", label: "Ruanda" },
{ value: "ro", label: "Rumunija" },
{ value: "ru", label: "Rusija" },
{ value: "vi", label: "S.A.D. Devičanska Ostrva" },
{ value: "sv", label: "Salvador" },
{ value: "ws", label: "Samoa" },
{ value: "sm", label: "San Marino" },
{ value: "st", label: "Sao Tome i Principe" },
{ value: "sa", label: "Saudijska Arabija" },
{ value: "sc", label: "Sejšeli" },
{ value: "pm", label: "Sen Pjer i Mikelon" },
{ value: "sn", label: "Senegal" },
{ value: "kn", label: "Sent Kits i Nevis" },
{ value: "lc", label: "Sent Lucija" },
{ value: "vc", label: "Sent Vinsent i Grenadini" },
{ value: "kp", label: "Severna Koreja" },
{ value: "mp", label: "Severna Marijanska Ostrva" },
{ value: "sl", label: "Sijera Leone" },
{ value: "sg", label: "Singapur" },
{ value: "sy", label: "Sirija" },
{ value: "us", label: "Sjedinjene Američke Države" },
{ value: "sk", label: "Slovačka" },
{ value: "si", label: "Slovenija" },
{ value: "sb", label: "Solomonska Ostrva" },
{ value: "so", label: "Somalija" },
{ value: "es", label: "Španija" },
{ value: "rs", label: "Srbija" },
{ value: "lk", label: "Šri Lanka" },
{ value: "sd", label: "Sudan" },
{ value: "sr", label: "Surinam" },
{ value: "bl", label: "Sv. Bartolomej" },
{ value: "mf", label: "Sv. Martin" },
{ value: "ch", label: "Švajcarska" },
{ value: "sj", label: "Svalbard i Janmajen Ostrva" },
{ value: "sz", label: "Svazilend" },
{ value: "se", label: "Švedska" },
{ value: "sh", label: "Sveta Jelena" },
{ value: "tj", label: "Tadžikistan" },
{ value: "th", label: "Tajland" },
{ value: "tw", label: "Tajvan" },
{ value: "tz", label: "Tanzanija" },
{ value: "tg", label: "Togo" },
{ value: "tk", label: "Tokelau" },
{ value: "to", label: "Tonga" },
{ value: "tt", label: "Trinidad i Tobago" },
{ value: "tn", label: "Tunis" },
{ value: "tm", label: "Turkmenistan" },
{ value: "tc", label: "Turks i Kajkos Ostrva" },
{ value: "tr", label: "Turska" },
{ value: "tv", label: "Tuvalu" },
{ value: "ug", label: "Uganda" },
{ value: "ae", label: "Ujedinjeni Arapski Emirati" },
{ value: "ua", label: "Ukrajina" },
{ value: "uy", label: "Urugvaj" },
{ value: "uz", label: "Uzbekistan" },
{ value: "wf", label: "Valis i Futuna Ostrva" },
{ value: "vu", label: "Vanuatu" },
{ value: "va", label: "Vatikan" },
{ value: "gb", label: "Velika Britanija" },
{ value: "ve", label: "Venecuela" },
{ value: "vn", label: "Vijetnam" },
{ value: "zm", label: "Zambija" },
{ value: "eh", label: "Zapadna Sahara" },
{ value: "zw", label: "Zimbabve" },
]
  
  export default {
    nationality,
  }
  
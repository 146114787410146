import React, { Component } from 'react';
import PassengerContext from '../../context/passenger-context';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';

class ContactFields extends Component {
    static contextType = PassengerContext;

    handler = (e, field) => {
        this.context.save_booking.passengers[0][field] = e.target.value;
        this.forceUpdate();
    }

    render() {
      return (
        <Card className="passenger-card">
            <Grid  container spacing={16}>
                <Grid item xs={12} className="contact-info-title">Kontakt podaci</Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Kontakt Email"
                        placeholder="npr. primer@gmail.com"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.context.save_booking.passengers[0].email || ''}
                        onChange={(e) => this.handler(e, 'email')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Kontakt Telefon"
                        placeholder="npr. +381 62 232 2322"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.context.save_booking.passengers[0].phone || ''}
                        onChange={(e) => this.handler(e, 'phone')}
                        />
                </Grid>
            </Grid>
        </Card>
      )
    }
  }

export default ContactFields;
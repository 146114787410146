import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import { Grid } from '@material-ui/core';
import MapIcon from "@material-ui/icons/Map";
import CheckIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ItineraryJourney from "../Itinerary/ItineraryJourney";

class FlightSummary extends Component {

    render(){
        return(
            <Card>
                <Grid container style={{padding: 16}} className="padding-16" justify="space-between">
                    <Grid item xs={12} className="title-head">
                        <span><MapIcon /></span>
                        <span>Pregled puta</span>
                    </Grid>
                    <Grid item xs={5}>
                        <p className="dep-title">Polazak</p>
                        <ItineraryJourney return={0} />
                    </Grid>
                    {this.props.oneway === 0 ? '' :
                    <Grid item xs={5}>
                        <p className="ret-title">Povratak</p>
                        <ItineraryJourney return={1} />
                    </Grid> }
                </Grid>

                <Grid container style={{padding: 16}}>
                    <Grid item md={12} className="title-head">
                        <span><CheckIcon style={{color: '#33c771', marginRight: 10, fontSize: 35}} /></span>
                        <span>Najletovi garancija</span>
                    </Grid>
                    <Grid item md={12}>
                        <p>Štiti vas u slučaju izmena u redu letenja, otkazivanja ili kašnjenja leta. Mi ćemo vam kupiti karte za alternativni let do krajnjeg odredišta ili refundirati deo putovanja u kome je došlo do izmena. Više informacija</p>
                    </Grid>
                </Grid>

                <Grid container style={{padding: 16}}>
                    <Grid item md={12} className="title-head">
                        <span><WarningIcon style={{color: '#F9AA33', marginRight: 10, fontSize: 35}} /></span>
                        <span>Viza je možda potrebna</span>
                    </Grid>
                    <Grid item md={12}>
                        <p>Ovo obaveštenje je samo informativne prirode. Kontaktirajte ambasadu ili ministarstvo spoljnih poslova vaše zemlje da biste bili sigurni.</p>
                    </Grid>

                </Grid>
            </Card>
        )
    }
}

export default FlightSummary;

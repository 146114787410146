import React, {Component} from 'react';
import { Grid } from '@material-ui/core';
import ItineraryFlightDate from "./itineraryFlightDate";
import ItineraryFlightOverview from "./itineraryFlightOverview";
import ItineraryFlightDetails from "./ItineraryFlightDetails";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateIcon from '@material-ui/icons/DateRangeRounded';
import Airplane from '@material-ui/icons/AirplanemodeActiveRounded';

export class SingleItinerary extends Component {
    render() {
        const data = this.props.data;
        const iconStyle = {
            fontSize: 20,
            color: "#666",
            fill: "#666"
        }
        const iconContainer = {
            textAlign: "center",
            height: 38,
        }
        const dateStyle = {
            fontSize: 14,
            fontWeight: 600,
        }
        const flightOverviewBoxStyle = {
            border: "1px solid #eee",
            borderRadius: 4,
            transition: "all .4s ease-in-out"
        }
        return (
            <React.Fragment>
                <ExpansionPanel style={{marginBottom: 0}} className={"SingleItinerary"}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{padding: '0 16px', margin: 0}} IconButtonProps={{"data-expand-icon": "true"}}>
                        <Grid container spacing={16} style={{padding: 0, marginBottom: 0}} alignItems={"center"}>
                            <Grid item md={2} style={iconContainer}><DateIcon style={iconStyle}/></Grid>
                            <Grid item md={10} style={dateStyle}>
                                <ItineraryFlightDate date={data.dtime_utc} />
                            </Grid>

                            <Grid item md={2} style={iconContainer}><Airplane style={iconStyle}/></Grid>
                            <Grid item md={10} style={flightOverviewBoxStyle} className="flightOverviewBox">
                                <ItineraryFlightOverview data={data} />
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{paddingTop: 0}}>
                        <Grid container spacing={16} justify="flex-end">
                            <Grid item md={10}>
                                <ItineraryFlightDetails data={data} />
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </React.Fragment>
        )
    }
}

import React, {Component} from 'react';
import Moment from 'react-moment';
import 'moment/locale/sr';


export default class ItineraryFlightDate extends Component {
    render() {
        return (
            <React.Fragment>
                <Moment unix locale="sr" format="DD. MMM YYYY">{this.props.date}</Moment>
            </React.Fragment>
        )
    }
}
const initialState = {
  snackbar: {
    open: false,
    variant: 'error',
    message: 'Error Message',
  },
  dialog: {
    show: false,
    title: '',
    body: '',
    reload: true,
    back_to_search: true,
  },
  booking_details: {
    from: '',
    to: '',
    oneway: false,
    passengers: 1,
  },
  reservation_bill: {
    freeBags: [
      {
        personal_item: 0,
        cabin_bag: 0,
        hold_bag: 0,
        unmounted: false
      }
    ],
    paidBags: []
  },
  save_booking: {
    lang: "en",
    bags: 3,
    passengers: [
      {
        name: "",
        surname: "",
        title: "",
        phone: "",
        birthday: null,
        b_day: null,
        b_month: null,
        b_year: "",
        expiration: null,
        e_day: null,
        e_month: null,
        e_year: "",
        cardno: "",
        nationality: "",
        nationality_full: "",
        email: "",
        category: "",
        hold_bags: {},
      },
    ],
    locale: "en",
    currency: "usd",
    booking_token: null,
    affily: 'najletovi',
    booked_at: 'najletovi',
    user_id: "test",
    secret_token: "test",
    immediate_confirmation: false
  },
  save_booking_response: null,
  check_flights_response: null,
  loading: true,
  change: false
}

const globalState = (state = initialState, action) => {
  console.log('[action type] ', action.type);
  // variables
  let reservationBill = null;

  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: action.loading
      }
    case 'CHECK_FLIGHTS_RESPONSE':
      return {
        ...state,
        check_flights_response: action.payload
      }

    case 'ERROR_DIALOG':
      let dialog = state.dialog;
      dialog.show = true;
      dialog.title = action.title;
      dialog.body = action.body;
      return {
        ...state,
        dialog: dialog,
        loading: false
      }

    case 'SHOW_SNACKBAR':
      let showSnackbar = state.snackbar;
      showSnackbar.open = true;
      showSnackbar.variant = action.variant;
      showSnackbar.message = action.message;

      return {
        ...state,
        snackbar: showSnackbar
      }

    case 'HIDE_SNACKBAR':
      let hideSnackbar = state.snackbar;
      hideSnackbar.open = false;

      return {
        ...state,
        snackbar: hideSnackbar
      }


    case 'FLIGHT_DETAILS':
      return {
        ...state,
        booking_details: action.payload
      }

    case 'ADD_PASSENGER':
      let addPassenger = state.save_booking;
      addPassenger.passengers.push({});
      reservationBill = state.reservation_bill;
      reservationBill.paidBags.push({items: 0, amount: 0});
      reservationBill.freeBags.push({personal_item: 0, cabin_bag: 0, hold_bag: 0, unmounted: false})

      return {
        ...state,
        save_booking: addPassenger,
        reservation_bill: reservationBill
      }

    case 'REMOVE_PASSENGER':
      let removePassenger = state.save_booking;
      reservationBill = state.reservation_bill;


      reservationBill.paidBags.splice(action.id, 1);
      reservationBill.freeBags.splice(action.id, 1);
      removePassenger.passengers.splice(action.id, 1);


      return {
        ...state,
        save_booking: removePassenger,
        reservation_bill: reservationBill
      }

    case 'ADD_FREE_BAGS':
      reservationBill = state.reservation_bill;
      reservationBill.freeBags[action.id][action.category] += 1;

      return {
        ...state,
        reservation_bill: reservationBill
      }
    case 'BAG_WILL_UNMOUNT':
      reservationBill = state.reservation_bill;
      if(reservationBill.freeBags[action.id])
        reservationBill.freeBags[action.id].unmounted = true;

      return {
        ...state,
        reservation_bill: reservationBill
      }

    case 'ADD_HOLD_BAGS':
      const bags = Object.keys(state.check_flights_response.bags_price).length;
      let save_booking = state.save_booking;
      let hold_bags = {};

      state.check_flights_response.flights.map(flight => {
        hold_bags[flight.id] = {};

        for (let i = 0; i < bags; i++)
          hold_bags[flight.id][i] = 0;

      });

      save_booking.passengers[action.id].hold_bags = hold_bags;
      return {
        ...state,
        save_booking: save_booking
      }

    case 'ADD_PAID_BAGS':
      let addBagSaveBooking = state.save_booking;
      for (let key in addBagSaveBooking.passengers[action.id].hold_bags) {
        if (addBagSaveBooking.passengers[action.id].hold_bags.hasOwnProperty(key)) {
          for (let bag in addBagSaveBooking.passengers[action.id].hold_bags[key]) {
            if (bag == action.bag_id) {
              addBagSaveBooking.passengers[action.id].hold_bags[key][bag] = 1;
            }
          }
        }
      }
      reservationBill = state.reservation_bill;
      if (reservationBill.paidBags[action.id] === undefined)
        reservationBill.paidBags.push({items: 0, amount: 0})

      reservationBill.paidBags[action.id].items += 1;
      reservationBill.paidBags[action.id].amount += action.price;

      return {
        ...state,
        reservation_bill: reservationBill,
        save_booking: addBagSaveBooking
      }

    case 'REMOVE_PAID_BAGS':
      let removeBagSaveBooking = state.save_booking;
      for (let key in removeBagSaveBooking.passengers[action.id].hold_bags) {
        if (removeBagSaveBooking.passengers[action.id].hold_bags.hasOwnProperty(key)) {
          for (let bag in removeBagSaveBooking.passengers[action.id].hold_bags[key]) {
            if (bag == action.bag_id) {
              removeBagSaveBooking.passengers[action.id].hold_bags[key][bag] = 0;
            }
          }
        }
      }
      reservationBill = state.reservation_bill;
      if (reservationBill.paidBags[action.id].items) {
        reservationBill.paidBags[action.id].items -= 1;
        reservationBill.paidBags[action.id].amount -= action.price;
      } else
        reservationBill.paidBags.splice({items: 0, amount: 0})

      return {
        ...state,
        reservation_bill: reservationBill,
        save_booking: removeBagSaveBooking
      }
    default:
      return state;
  }

};

export default globalState;


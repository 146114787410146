import React, {Component} from 'react';
import Moment from 'react-moment';
import { Grid } from '@material-ui/core';

import 'moment/locale/sr';

export default class ItineraryFlightOverview extends Component {
    render() {
        const data = this.props.data;
        const timeStyle = {
            fontSize: 16,
            color: "#333",
            marginRight: 5,
        }
        const airportStyle = {
            fontSize: 14,
            fontWeight: "bold",
            color: "#7c8b99",
            lineHeight: 1.5
        }
        return (
            <React.Fragment>
                <Grid container spacing={8} alignItems="center" alignContent="center">
                    <Grid item md={10}>
                        <Grid container spacing={8}>
                            <Grid item>
                                <Moment unix format="HH:mm" style={timeStyle}>{data.dtime_utc}</Moment>
                            </Grid>
                            <Grid item style={airportStyle}>
                                {data.src_name} {data.src}
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item>
                                <Moment unix format="HH:mm" style={timeStyle}>{data.atime_utc}</Moment>
                            </Grid>
                            <Grid item style={airportStyle}>
                                {data.dst_name} {data.dst}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={2} style={{textAlign: 'right', height: 40}}>
                        <img src={"//images.kiwi.com/airlines/64x64/" + data.operating_airline.iata + ".png"} alt="" width="35" />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}


import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import BaggageBox from "./BaggageBox";
import {connect} from "react-redux";

class BaggageOverview extends Component {

    render() {
        const id = this.props.id;
        const baggage = this.props.check_flights_response ? this.props.check_flights_response.baggage.definitions : null;
        return (
            <Grid container spacing={32} style={{marginTop: 18, marginBottom: 18}}>
                {this.props.check_flights_response ?
                    baggage.hand_bag.map((bag, index) => {
                        return <BaggageBox data={bag} key={index} id={id} />
                    })
                    : null}

                {this.props.check_flights_response ?
                    baggage.hold_bag.map((bag, index) => {
                        return <BaggageBox data={bag} key={index} id={id} bag_id={index}/>
                    })
                : null}
            </Grid>
        )
    }
}


const mapStateToProps = state => {
    return {
        dialog: state.dialog,
        loading: state.loading,
        check_flights_response: state.check_flights_response,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBookingDetails: (payload) => dispatch({type: 'FLIGHT_DETAILS', payload: payload}),
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaggageOverview);

import axios from "axios";

export const getCheckFlights = (token = undefined, pnum = 1, curr = 'EUR', userId, adults = 1, children = 0, infants = 0) => {
  console.log('function began');
  return dispatch => {
    let request_url = '';
    if (token !== undefined) {
      request_url = 'https://booking-api.skypicker.com/api/v0.1/check_flights?v=2' +
        '&booking_token=' + token + '&bnum=3' + '&pnum=' + pnum + '&affily=picky_{market}' +
        '&currency=' + curr + '&visitor_uniqid=' + userId + '&adults=' + adults +
        '&children=' + children + '&infants=' + infants;
    } else {
      request_url = localStorage.getItem("query_url");
    }
    console.log("[QUERY URL] ", request_url);

    return axios.get(request_url)
      .then(res => {
        dispatch({type: 'CHECK_FLIGHTS_RESPONSE', payload: res.data});
        localStorage.setItem("query_url", request_url);
        console.log("[CHECK FLIGHTS RESPONSE] ", res.data);
      }).then(res => {
        dispatch({type: 'LOADING', loading: false});
      }).catch(error => {
      dispatch({
        type: 'ERROR_DIALOG',
        title: 'Žao nam je, došlo je do problema',
        body: 'Došlo je do promene u ponudi ovog leta. Molimo Vas osvežite rezultate ili se vratite na pretragu letova.'
      })
      console.log("[CHECK FLIGHTS ERROR] ", error.response);
    });
  }
}

import React from 'react';
import Grid from '@material-ui/core/Grid';

const BookingSidebar = (props) => {

    const totalPrice = () => {
      return (props.save_booking.passengers.length * props.check_flights_response.one_passenger + paidBagsAmount()).toFixed(2);
    }

    const showPersonalItems = () => {
      let personal_items = 0;
      props.reservation_bill.freeBags.map(a => personal_items += a.personal_item);

      return personal_items;
    }

  const showCabinBags = () => {
    let cabin_bag = 0;
    props.reservation_bill.freeBags.map(a => cabin_bag += a.cabin_bag);

    return cabin_bag;
  }

  const showHoldBags = () => {
    let hold_bag = 0;
    props.reservation_bill.freeBags.map(a => hold_bag += a.hold_bag);

    return hold_bag;
  }

    const showPaidBags = () => {
      return props.reservation_bill.paidBags.some(bag => bag.items);
    }

    const paidBagsItems = () => {
      let items = 0;
      props.reservation_bill.paidBags.map((a) => items += a.items);
      return items;
    }

    const paidBagsAmount = () => {
      let amount = 0;
      props.reservation_bill.paidBags.map((a) => amount += a.amount);
      return amount;
    }

      return (
        <React.Fragment>
          <Grid container justify='space-between' className="booking-summary">
              <Grid item xs={12} className="summary-title">Pregled avio karte</Grid>


              <Grid item xs={6}>{props.save_booking.passengers.length} x putnik</Grid>
              <Grid item xs={6} style={{textAlign: "right"}}>{props.check_flights_response.one_passenger} EUR</Grid>

            {showPersonalItems() ?
              <React.Fragment>
                <Grid item xs={6}>{showPersonalItems()} x Lične stvari</Grid>
                <Grid item xs={6} style={{textAlign: "right"}}>BESPLATNO</Grid>
              </React.Fragment>
              : null}

            {showCabinBags() ?
              <React.Fragment>
                <Grid item xs={6}>{showCabinBags()} x Ručni prtljag prtljag</Grid>
                <Grid item xs={6} style={{textAlign: "right"}}>BESPLATNO</Grid>
              </React.Fragment>
              : null}

            {showHoldBags() ?
              <React.Fragment>
                <Grid item xs={6}>{showHoldBags()} x Dodatan prtljag</Grid>
                <Grid item xs={6} style={{textAlign: "right"}}>BESPLATNO</Grid>
              </React.Fragment>
              : null}

              {showPaidBags() ?
                  <React.Fragment>
                      <Grid item xs={6}>{paidBagsItems()} x Dodatan prtljag</Grid>
                      <Grid item xs={6} style={{textAlign: "right"}}>{paidBagsAmount()} EUR</Grid>
                  </React.Fragment>
                  : null}

              {/*{this.getPaidBags()}*/}
              <div className="divider-line"></div>

              <Grid item xs={6}>Ukupno:</Grid>
              <Grid item xs={6} style={{textAlign: "right"}}>{totalPrice()} EUR</Grid>
              <Grid item md={12} style={{wordBreak: "break-all"}}>
              </Grid>
          </Grid>
        </React.Fragment>
      )
  }

export default BookingSidebar;

export const months  = [
    {},
    {value: 1, label: 'Januar'},
    {value: 2, label: 'Februar'},
    {value: 3, label: 'Mart'},
    {value: 4, label: 'April'},
    {value: 5, label: 'Maj'},
    {value: 6, label: 'Jun'},
    {value: 7, label: 'Jul'},
    {value: 8, label: 'Avgust'},
    {value: 9, label: 'Septembar'},
    {value: 10, label: 'Oktobar'},
    {value: 11, label: 'Novembar'},
    {value: 12, label: 'Decembar'},
    ]
      
export default months;
      
import React, {Component} from 'react';

class Homepage extends Component {
  render() {
    return (
      <React.Fragment>
        <h1>Homepage</h1>
        <a href="/booking/BEG/BVA/RSD/2/0/430f1d65-3ee3-4c35-8f6f-393761f5b982/oKWrjE3p79Fphzv+baF3C2h1PDgCet9WaEXSzPuPgCEAv+on9q2YST/DjPlyl3WZYCdLEXKEOBtJT96uSi5cLvEfHS/r4ptstFPMzb8oeL6HtCKh/nzMgVI1roK8esqfWgdatkDiA9tnPVgkHGKWcabNFhzH3iZQGj4qEpPsY2zAImeIjAh0GK0+AToKmAypfPcPgAjx49FwbO19TDrhEqwubnQTkF61872t6gMuQ9hEacUycX75ypoBhM4ANOpvTrbvEU07pcMCLs+4Qdo5itPAI8jZOA0eJLdYNjZJaYGiHJ2fnyvUuCip+Bm7Q/TprAWGne5WFCMZGk34Yb7nlhxQYKKCX1acCSx8b+bsF6l+tMTqyB8gJKvKtiSh1WaOkF/jbDdv25l+6gYWWTOpf3NtiX/XbNamU4Ag17w5KJmgB22xT6zPr/4GSVV+/ezfah5bnP5kyFOdHHsGBa07Sk3qFMomDSZ8rcExMfeDUNymqQb0LOFOFPp2vqE5ISGcbQwHSw0Rj6QTQ+oAd+teF2ICcb+b0XZUaJuk7TA6NlamOnVnIRADyuayWpLaR7XtZ8u1wkgTyqfz3clX/JAXdF+lUPQKoWao8OOwV7BMcil7JBubvbjE468ebgBZHmDaR7IUyJ9jPdGaeVxNutu9Exjxs0A1FrcWnE8ZknIy67aDOCRGB9x6Ot2iCPxK6kjxbVPy3vWUI8m4DvTMRbh+CDTLKarg3reLxdThFyQFbk3cl+7DySz5Vt6x8hYJGuTchb/MBZu9QOe4+LG05tjYhcKWgPtNSxo+lqmtAhFPNyW6t6P0BtkD5tz6wKLpFX0v2cFrAbVW9MiKmVT1hxNZk2DeVCXnncs9tmQRrjNMnR9jgs5Ir5qJcD3WTPEang5gxSPS6aa1RJcIN+oyXYpYh9j37ZwfgHYL0JVLyDpYZlz6oonyc0UCz9Ws/uso+kT9t5vg4b29Gl7S8Zrksb87Zw=="> TESTNI LINK </a>
      </React.Fragment>
    )
  }
}

export default Homepage

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getCheckFlights} from '../api/getCheckFlights';

import {Link} from 'react-router-dom';
import Loader from '../components/Loader';
import DialogPopup from '../components/DialogPopup';
import FlightSummary from '../components/Booking/FlightSummary';
import {Button, Grid} from '@material-ui/core';
import BookingSidebar from '../components/BookingSidebar';


class Booking extends Component {

  componentDidMount = () => {
    this.props.dispatch(getCheckFlights(
      this.props.match.params.token,
      this.props.match.params.pass,
      this.props.match.params.curr,
      this.props.match.params.id,
      this.props.match.params.pass
    ));
    this.props.getBookingDetails({
      from: this.props.match.params.from,
      to: this.props.match.params.to,
      oneway: this.props.match.params.oneway,
      passengers: this.props.match.params.pass
    });
  }

    render() {
        const url_params = this.props.location.pathname.substr(9);
        return (
          <React.Fragment>
            {this.props.loading ? <Loader /> :
            <Grid container padding={16} justify='space-between' className="container">
                <Grid item md={6} style={{marginBottom: 30}}>
                    <Button>
                        <Link to="https://www.najletovi.rs/" style={{color: "#ec297b"}}> Nazad na pretragu </Link>
                    </Button>
                </Grid>
                <Grid item md={6} style={{marginBottom: 30}}>
                    {this.props.match.params.from} {this.props.match.params.to}
                </Grid>
              <Grid item xs={8}>
                <FlightSummary oneway={this.props.fullState.booking_details.oneway}/>

                <Grid container justify="flex-end" style={{marginTop: 50}}>
                    <Button className="nextButton" style={{backgroundColor: "#ec297b", padding: 0}}>
                        <Link to={"/info/"+url_params} style={{color: "#fff", padding: "10px 50px"}}>Podaci putnika</Link>
                    </Button>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                {this.props.fullState.check_flights_response ?
                <BookingSidebar {...this.props.fullState} /> : '' }
              </Grid>
            </Grid>
            }

            <DialogPopup show={this.props.dialog.show} title={this.props.dialog.title} body={this.props.dialog.body} reloadBtn={true} backToSearch={true}/>
          </React.Fragment>
        )
    }
  }


const mapStateToProps = state => {
  return {
    dialog: state.dialog,
    loading: state.loading,
    fullState: state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBookingDetails: (payload) => dispatch({type: 'FLIGHT_DETAILS', payload: payload}),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Booking);

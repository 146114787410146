import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";

import Homepage from './views/Homepage'
import Booking from './views/Booking'
import PassengerInfo from './views/PassengerInfo'
import Payment from './views/Payment'

import './assets/css/fonts.css';
import './assets/css/default.css';
import './assets/css/bags.css';
import {Provider} from "react-redux";


class App extends Component {

  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Homepage}/>
            <Route path="/booking/:from/:to/:curr/:pass/:oneway/:id/:token+" component={Booking}/>
            <Route path="/info/:from/:to/:curr/:pass/:oneway/:id/:token+" component={PassengerInfo}/>
            <Route path="/payment/:from/:to/:curr/:pass/:oneway/:id/:token+" component={Payment}/>
            <Route path="/booking/" component={Booking}/>
            <Route path="/info/" component={PassengerInfo}/>
            <Route path="/payment/" component={Payment}/>
            <Route component={Booking}/>
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from "react-router-dom";
import BookingSidebar from '../components/BookingSidebar';
import Snackbar from '@material-ui/core/Snackbar';
import FormGroup from '../components/Passenger/FormGroup';
import ContactFields from '../components/Passenger/ContactFields';
import { Button } from '@material-ui/core';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Grid from '@material-ui/core/Grid';
import Loader from '../components/Loader';
import DialogPopup from '../components/DialogPopup';
import {getCheckFlights} from "../api/getCheckFlights";

class PassengerInfo extends Component {

  componentDidMount = () => {
    this.props.dispatch(getCheckFlights(
      this.props.match.params.token,
      this.props.match.params.pass,
      this.props.match.params.curr,
      this.props.match.params.id,
      this.props.match.params.pass
    ));
    this.props.getBookingDetails({
      from: this.props.match.params.from,
      to: this.props.match.params.to,
      oneway: this.props.match.params.oneway,
      passengers: this.props.match.params.pass
    });
  }

    eachPassenger = (pass, index) => {
        return <FormGroup  key={index} id={index} data={pass} removePassenger={this.props.removePassenger}/>
    }

    render() {
        const url_params = this.props.location.pathname.substr(6);

        return (
            <React.Fragment>
                {this.props.loading ? <Loader /> :
                    <Grid container padding={16} justify='space-between' className="container">
                        <Grid item md={8} xs={12}>
                            <form>
                                {this.props.save_booking.passengers.map(this.eachPassenger)}

                                <div style={{textAlign: "center", margin: "20px 0 50px 0"}}>
                                    <Button variant="contained" style={{backgroundColor: "#7f3f97", color: "#fff"}} onClick={this.props.addPassenger}>
                                        Dodaj putnika <PersonAdd style={{marginLeft: 10}}/>
                                    </Button>
                                </div>

                                <ContactFields />
                            </form>

                            <Grid container justify="space-between" style={{marginTop: 50}}>
                                <Button className="backButton" style={{backgroundColor: "#bbb", padding: 0}}>
                                    <Link to={"/booking/"+url_params} style={{color: "#fff", padding: "10px 50px"}}>Pregled puta</Link>
                                </Button>
                                <Button className="nextButton" style={{backgroundColor: "#ec297b", padding: 0}} onClick={console.log(this.props.save_booking)}>
                                    <Link to={"/payment/"+url_params} style={{color: "#fff", padding: "10px 50px"}}>Placanje</Link>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <BookingSidebar {...this.props.fullState} />
                        </Grid>

                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={this.props.snackbar.open}
                            variant={this.props.snackbar.variant}
                            autoHideDuration={3000}
                            onClose={this.props.hideSnackbar}
                            message={<span id="message-id">{this.props.snackbar.message}</span>}
                            />
                    </Grid>
                }

                <DialogPopup show={this.props.dialog.show} title={this.props.dialog.title} body={this.props.dialog.body} reloadBtn={true} backToSearch={true} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
  return {
    dialog: state.dialog,
    snackbar: state.snackbar,
    loading: state.loading,
    save_booking: state.save_booking,
    fullState: state
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBookingDetails: (payload) => dispatch({type: 'FLIGHT_DETAILS', payload: payload}),
    hideSnackbar: () => dispatch({type: 'HIDE_SNACKBAR'}),
    addPassenger: () => {
      dispatch({type: 'ADD_PASSENGER'})
      dispatch({type: 'SHOW_SNACKBAR', message: 'Novi putnik je dodat', variant: 'success'})
      },
    removePassenger: (id) => {
      dispatch({type: 'REMOVE_PASSENGER', id})
      dispatch({type: 'SHOW_SNACKBAR', message: 'Uklonili ste '+ id +'. putnika', variant: 'warning'})
    },
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PassengerInfo);


import React, {Component} from 'react';
import {SingleItinerary} from './SingleItinerary';
import {connect} from "react-redux";

class ItineraryJourney extends Component {


    render() {
        const isReturn = this.props.return;

        return (
            <React.Fragment>
                {this.props.check_flights_response.flights.map((flight, index) => {
                    if(flight.return === isReturn) {
                        return <SingleItinerary data={flight} key={index}/>
                    }
                })}
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        check_flights_response: state.check_flights_response
    };
};

export default connect(mapStateToProps)(ItineraryJourney);
